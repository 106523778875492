import React from "react";
import { hydrate } from "react-dom";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import "swiper/css";
// import reportWebVitals from "./reportWebVitals";

import router from "./routes";

const rootEl = document.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById("root"));

if (rootEl.hasChildNodes()) {
  hydrate(<RouterProvider router={router} />, rootEl);
} else {
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
