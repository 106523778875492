import styles from "./Reviews.module.scss";
import { ReactComponent as Star } from "@images/star.svg";
import reviewData from "@/pages/Reviews/reviewData";

export default function Reviews() {
  return (
    <div className={styles.root}>
      <div className={styles.contentWrap}>
        <h1 className={styles.h1}>Отзывы о нас</h1>
        <ul className={styles.reviewList}>
          {reviewData.map(({ rating, name, serviceName, date, reviewText }) => {
            const ratingMap = [];
            for (let i = 0; i < rating; i++) {
              ratingMap.push(<Star className={styles.star} key={i} />);
            }

            return (
              <li className={styles.reviewItem} key={date + name}>
                <div className={styles.reviewItemWrap}>
                  <div className={styles.rating}>{ratingMap}</div>
                  <div className={styles.name}>{name}</div>
                  <div className={styles.serviceName}>{serviceName}</div>
                  <div className={styles.date}>{date}</div>
                  <div className={styles.text}>{reviewText}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
