const pricesData = [
  {
    title: "Первичное обследование объекта в процессе производства работ.",
    data: [
      {
        value:
          "Первичное обследование объекта в период строительства при выполненных 2-х этажах с экспресс-отчетом  в пределах ЦКАД",
        price: "от 10 000",
      },
      {
        value:
          "Первичное обследование объекта в период строительства при выполненных 2-х этажах с полным отчетом в пределах ЦКАД",
        price: "от 12 000",
      },
    ],
  },
  {
    title: "Строительный контроль (технадзор) за строительством и ремонтом",
    data: [
      {
        value:
          "Первый и любой последующий выезд с составлением экспресс-отчета в пределах МКАД и до ЦКАД*",
        price: "10 000",
      },
      {
        value:
          "Первый и любой последующий выезд с составлением экспресс-отчета в районе ЦАО г. Москвы",
        price: "10 000",
      },
      {
        value:
          "Первый и любой последующий выезд с составлением экспресс-отчета за  ЦКАД*",
        price: "12 000",
      },
    ],
  },
  {
    title: "Проверка объекта недвижимости перед покупкой",
    data: [
      { value: "Проверка дома перед покупкой", price: "от 15 000" },
      { value: "Проверка квартиры перед покупкой", price: "от 15 000" },
      {
        value: "Тепловизионное обследование ЦКАД/за ЦКАД",
        price: "10 000/12 000",
      },
      {
        value: "Обследование инженерных систем ЦКАД/за ЦКАД",
        price: "10 000/12 000",
      },
      {
        value: "Обследование плоских и скатных кровель ЦКАД/за ЦКАД",
        price: "10 000/12 000",
      },
      {
        value: "Судебная/досудебная строительно-техническая экспертиза",
        price: "от 30 000",
      },
      { value: "Полный рабочий день на объекте", price: "30 000" },
      {
        value:
          "Проверка рабочей документации на предмет ошибок или оптимизации.",
        price: "3 000/час",
      },
      {
        value: "Поверочные расчеты/ расчеты конструктивных элементов",
        price: "от 5000",
      },
    ],
  },
  {
    title: "Проектирование инженерных систем",
    data: [
      {
        value: "Проектирование инженерных систем – Электрика (ЭОМ)",
        price: "150 руб./м2",
      },
      {
        value:
          "Проектирование инженерных систем – Отопление, водоснабжение, канализирование (ОВК)",
        price: "200 руб./м2",
      },
    ],
  },
];

export default pricesData;