import styles from "./Payment.module.scss";
import "./widget.css";
import { useRef, useState } from "react";

export default function Payment() {
    const payRef = useRef(null);
    const TPF = payRef.current;
    const [amountState, setAmountState] = useState('');

    const submitHandle = (e) => {
        e.preventDefault();

        // if(+amountState < 5000) {
        //     return alert('Минимально возможная оплата 5000 руб.');
        // }

        if (TPF.receipt) {
            if (!TPF.email.value && !TPF.phone.value)
                return alert("Поле E-mail или Phone не должно быть пустым");

            TPF.receipt.value = JSON.stringify({
                "EmailCompany": "mail@mail.com",
                "Taxation": "usn_income",
                "Items": [
                    {
                        "Name": TPF.description.value || "Оплата",
                        "Price": TPF.amount.value + '00',
                        "Quantity": 1.00,
                        "Amount": TPF.amount.value + '00',
                        "PaymentMethod": "full_prepayment",
                        "PaymentObject": "service",
                        "Tax": "none",
                    }
                ]
            });
        }
        window?.pay(TPF);
    }

    return (
        <div className={styles.root}>
            <div className={styles.contentWrap}>
                <h1 className={styles.h1}>Оплата</h1>
                <div className={styles.payment}>
                    <form ref={payRef} className="payform-tinkoff" name="payform-tinkoff" id="payform-tinkoff"
                          onSubmit={submitHandle}>
                        <input className="payform-tinkoff-row" type="hidden" name="terminalkey"
                               value="1700421688064"/>
                        <input className="payform-tinkoff-row" type="hidden" name="frame" value="false"/>
                        <input className="payform-tinkoff-row" type="hidden" name="language" value="ru"/>
                        <input className="payform-tinkoff-row" type="hidden" name="receipt" value=""/>
                        <input className="payform-tinkoff-row" type="text" placeholder="Сумма заказа"
                               name="amount" required onChange={e => setAmountState(e.target.value)} />
                        <input className="payform-tinkoff-row" type="hidden" placeholder="Номер заказа"
                               name="order"/>
                        <select className="payform-tinkoff-row select" name="description" placeholder="Описание заказа">
                            <option value="Строительный контроль">Строительный контроль</option>
                            <option value="Экспертиза объекта перед покупкой">Экспертиза объекта перед покупкой</option>
                            <option value="Экспертиза строительства">Экспертиза строительства</option>
                            <option value="Тепловизионное обследование">Тепловизионное обследование</option>
                        </select>
                        <input className="payform-tinkoff-row" type="text"
                               placeholder="ФИО плательщика" name="name"/>
                        <input className="payform-tinkoff-row" type="email" placeholder="E-mail"
                               name="email"/>
                        <input className="payform-tinkoff-row" type="tel"
                               placeholder="Контактный телефон" name="phone"/>
                        <input className="payform-tinkoff-row payform-tinkoff-btn"
                               type="submit" value="Оплатить"/>
                    </form>
                </div>
            </div>
        </div>
    );
}
