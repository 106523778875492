import styles from "./Button.module.scss";

export default function Button({ handler, className, children, theme }) {
  return (
    <button
      className={`${styles.root} ${styles[theme]} ${className}`}
      onClick={handler}
    >
      {children}
    </button>
  );
}
