import styles from "./FeedbackForm.module.scss";
import axios from "axios";
import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function FeedbackForm() {
  const formRef = useRef(null);
  const { pathname } = useLocation();
  const [submitStatus, setSubmitStatus] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const isTypeSelectHidden = [
    "/services/pre-purchase-inspection",
    "/services/pre-purchase-inspection/",
    "/services/construction-expertise",
    "/services/construction-expertise/",
  ].includes(pathname);

  const closeModal = () => {
    setModalOpen(false);
    setSubmitStatus(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    if (!formData.get("phone") || !formData.get("fio")) {
      setSubmitStatus("Не заполнены обязательные поля");
      setModalOpen(true);
      return;
    }
    setSubmitStatus("Отправляем...");
    axios
      .post("/mailSend.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        setSubmitStatus("Сообщение отправлено!");
      })
      .catch(() => {
        setSubmitStatus("что-то пошло не так, попробуйте позже");
      });
    setModalOpen(true);
  };

  return (
    <div className={styles.root}>
      <form
        className={styles.form}
        ref={formRef}
        name="submit"
        onSubmit={onSubmit}
      >
        <input
          type="hidden"
          id="location"
          name="location"
          value={pathname}
          className={styles.hidden}
        />
        <label htmlFor="fio" className={styles.textLabel}>
          <div className={styles.textLabelName}>Имя*</div>
          <input type="text" name="fio" id="fio" className={styles.textInput} />
        </label>
        <label htmlFor="phone" className={styles.textLabel}>
          <div className={styles.textLabelName}>Телефон*</div>
          <InputMask
            mask="+7 (999) 999-99-99"
            maskChar="_"
            type="tel"
            name="phone"
            id="phone"
            className={styles.textInput}
          />
        </label>
        <label htmlFor="address" className={styles.textLabel}>
          <div className={styles.textLabelName}>Адрес объекта</div>
          <input
            type="text"
            name="address"
            id="address"
            className={styles.textInput}
          />
        </label>
        {!isTypeSelectHidden ? (
          <label htmlFor="type" className={styles.selectLabel}>
            <div className={styles.selectLabelName}>Тип объекта</div>
            <select name="type" id="type" className={styles.select}>
              <option value="flat">Квартира</option>
              <option value="house">Дом</option>
            </select>
          </label>
        ) : (
          <input
            type="hidden"
            id="type"
            name="type"
            value="house"
            className={styles.hidden}
          />
        )}
        <label htmlFor="area" className={styles.textLabel}>
          <div className={styles.textLabelName}>Площадь</div>
          <input
            type="text"
            name="area"
            id="area"
            className={styles.textInput}
          />
        </label>
        <label htmlFor="comment" className={styles.textareaLabel}>
          <div className={styles.textareaLabelName}>Комментарий</div>
          <textarea name="comment" id="comment" className={styles.textarea} />
        </label>
        <button
          className={styles.submitBtn}
          type="submit"
          name="submit"
          disabled={submitStatus !== null}
        >
          Решить проблему
        </button>
        <div className={styles.privacyAgreeText}>
          Нажимая кнопку "Решить проблему" Вы подтверждаете согласие на передачу и
          обработку персональных данных в соответствии с{" "}
          <Link to={"privacy-policy"}>Политикой конфиденциальности</Link>
        </div>
      </form>
      <Modal
        isOpen={modalOpen}
        className={styles.modalRoot}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalMessage}>{submitStatus}</div>
          <button
            className={styles.submitBtn}
            type="button"
            onClick={closeModal}
          >
            &nbsp;&nbsp;OK&nbsp;&nbsp;
          </button>
        </div>
      </Modal>
    </div>
  );
}
