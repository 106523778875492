import styles from "./ServiceContent.module.scss";

export default function PrePurchaseInspection() {
  return (
    <div className={styles.root}>
      <p className={styles.paragraph}>
        Посмотрели дом, который собираетесь купить, и он понравился.
      </p>

      <p className={styles.paragraph}>
        А что дальше? <br />
        Можно купить наугад, без проверки, и дальше как повезет (да, да то самое
        «Авось»).
      </p>

      <p className={styles.paragraph}>
        Но, необходимо привлечь специалиста в экспертизе дома перед покупкой для
        оценки всех имеющихся рисков.
      </p>
      <p className={styles.paragraph}>
        Поэтому на помощь приходим мы. И проверяем дом который вы собрались
        покупать с отделкой или без.
      </p>
      <p className={styles.listHeader}>Что мы поверяем:</p>
      <ul className={styles.list}>
        <li>
          1. Полный визуальный осмотр стен, перегородок, потолка выявление
          наличия трещин и определение их характера.
        </li>
        <li>
          2. Толщину стен, по возможности определение типа материала и
          выполнение расчета на сопротивление теплопередачи ограждающих
          конструкций.
        </li>
        <li>
          3. Тепловизионное обследование внешних стен на тепловые потери (только
          в отопительный период).
        </li>
        <li>
          4. Сборку электрического щитка, работоспособности всех розеток и
          правильности их подключения. Контроль срабатывания розеток, УЗО и
          Дифференциальных автоматических выключателей на ток утечки, проверка
          изоляции между шинами фаза-ноль, фаза-земля, земля-ноль.
        </li>
        <li>
          5. Анализ сантехники и отопления, в том числе на предмет протечек.
        </li>
        <li>
          6. Тепловизионное обследование работоспособности отопления и теплых
          полов.
        </li>
        <li>7. Наличие в стеклопакетах низко эмиссионного покрытия.</li>
        <li>
          8. Монтаж скатной кровли и определения правильности установки
          соответствующих мембран (это одна из самых частых и дорогостоящих
          ошибок).
        </li>
        <li>9. Осмотр плоских кровель и поиск повреждений.</li>
        <li>
          10. Прочности бетона и армирования (при наличии открытых участков)
        </li>
      </ul>
      <p className={styles.paragraph}>
        Временной интервал для проведения экспертизы здания перед покупкой
        составляет не менее 2-х часов, в зависимости от площади здания.
      </p>

      <p className={styles.paragraph}>
        Скрытых дефектов, которые возможно увидеть имея оборудование, достаточно
        много.
      </p>
      <p className={styles.paragraph}>Экспресс отчет прилагается.</p>
      <p className={styles.paragraph}>
        И только после осмотра специалистами возможно принимать взвешенное
        решение о приобретении дома и понимая какие дополнительные затраты
        ожидают.
      </p>

      <p className={styles.paragraph}>
        Мы много проверили домов и были объекты которые полностью готовы к
        проживанию с минимальными затратами, и с достаточно большими вложениями,
        но владельцы перед сделкой уже понимают, во что им обойдется ремонт и
        как правило на эту сумму предоставляется скидка на при оформлении
        объекта недвижимости.
      </p>
    </div>
  );
}
