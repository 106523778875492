import styles from "./Layout.module.scss";
import Header from "@components/Header";
import Footer from "@components/Footer";

export default function Layout({ children }) {
  return (
    <div className={styles.layoutRoot}>
      <Header />
      <div className={styles.layoutContent}>{children}</div>
      <Footer />
    </div>
  );
}
