import styles from "./ServiceContent.module.scss";

export default function RepairExpertise() {
  return (
    <div className={styles.root}>
      <p className={styles.paragraph}>
        Наша команда помогает делать ремонт в квартире, офисе или загородном
        доме - правильно!
      </p>
      <p className={styles.paragraph}>
        Проверим все выполняемые работы, дадим рекомендации как исправить и
        проконтролируем исправление.
      </p>
      <p className={styles.listHeader}>
        Выполняем проверку следующих видов работ:
      </p>

      <ul className={styles.list}>
        <li>1. Штукатурка - плоскость, вертикальность, отслоение.</li>
        <li>
          2. Стяжка пола - горизонтальность, плоскостность, деформационные швы,
          демпферная лента.
        </li>
        <li>
          3. Шпаклёвка перед поклейкой обоев или покраской - плоскость, шлифовку
          лампой Лосева, вертикальность.
        </li>
        <li>
          4. Каркас потолка и монтажа ГКЛ листов, одно и много уровневых,
          криволинейных.
        </li>
        <li>5. Укладку напольных и настенных покрытий и плинтусов.</li>
        <li>6. Инженерных коммуникаций (электрика, сантехника)</li>
        <li>
          7. Гидравлические испытания систем отопления, ХВС, ГВС, котельной.
        </li>
        <li>
          8. Сборку электрического распределительного щитка, срабатывание УЗО и
          дифференциальных автоматов на ток утечки и розеток.
        </li>
        <li>9. Тепловизионное обследование в случае необходимости.</li>
      </ul>

      <p className={styles.paragraph}>
        Если у вас возникли сомнения, в правильности выполнения работ вашими
        строителями, значит ошибки уже точно есть. Вызывайте нас и не
        стесняйтесь. Затраты на наши услуги окупаются в гораздо большем размере.
      </p>
    </div>
  );
}
