const reviewData = [
  {
    rating: 5,
    name: "Марина",
    serviceName: "Экспертиза ремонта",
    date: "8 марта 2023",
    reviewText: "Отличный специалист, компетентный и пунктуальный",
  },
  {
    rating: 5,
    name: "Маргарита",
    serviceName: "Техническое обследование зданий",
    date: "26 января 2023",
    reviewText:
      "Геннадий очень ответственно подходит к работе, методично и качественно исследует объекты. По результатам работы выдал нам подробный отчет с фотографиями и описанием выявленных проблем. Приглашали провести экспертизу дома и бани, которые планировали покупать. Геннадий помог выявить проблемы с кровлей, впоследствии строители дома подтвердили, что использовали не тот материал, который был заложен в проекте.",
  },
  {
    rating: 5,
    name: "Иосиф",
    serviceName: "Контроль ремонта",
    date: "23 января 2023",
    reviewText:
      "Отличное качество сервиса. Благодаря Геннадию ремонт в квартире был сделан качественнее: выявлялись недочеты в работе строителей, которые были мне неочевидны; строителям давались советы лучшего выполнения работ и т.д.\nОчень рекомендую.",
  },
  {
    rating: 5,
    name: "Анна",
    serviceName: "Контроль строительства",
    date: "10 ноября 2022",
    reviewText:
      "Геннадий контролировал строительство дома с фундамента до отделки. Оперативно выезжал на важные этапы сдачи -приемки, своевременно делал замечания бригаде, исправлял их ошибки и недочёты. Всегда на связи, отвечал на возникающие в процессе строительства вопросы. В арсенале профи имеется все необходимое оборудование и инструмент для оценки выполненной работы и отклонений от нормы. Работой Геннадия очень довольна, надеюсь на дальнейшее сотрудничество.",
  },
  {
    rating: 5,
    name: "Павел",
    serviceName: "Строительная экспертиза",
    date: "31 августа 2022",
    reviewText:
      "Компетентный, профессиональный и знающий специалист . Приехал точно в оговоренное время. Сделал в срок качественный отчет о выявленных нарушениях и рассчитал стоимость восстановительного ремонта. Застройщик перестал увиливать и преступил к устранению недостатков.! Рекомендую !",
  },
  {
    rating: 5,
    name: "Марина",
    serviceName: "Экспертиза ремонта",
    date: "8 июня 2022",
    reviewText:
      "Компетентный, знающий специалист с отличным оборудованием, приятный и коммуникабельный ! Жалею, что не обратилась к Геннадию в процессе ремонта, это помогло бы избежать многих ошибок ! Рекомендую !",
  },
  {
    rating: 5,
    name: "Игорь",
    serviceName: "Экспертиза ремонта",
    date: "1 апреля 2022",
    reviewText: "Со знанием дела и ответственностью. Спасибо.",
  },
  {
    rating: 5,
    name: "Ольга Капитонова",
    serviceName: "Контроль строительства",
    date: "11 января 2022",
    reviewText:
      "Очень грамотный, серьёзный специалист. Помогал во всех вопросах бригаде и не давал им спуска, постоянно была связь по ватс апу. Буду обязательно рекомендовать и обращаться!",
  },
  {
    rating: 5,
    name: "Алина",
    serviceName: "Тепловизионные обследования",
    date: "25 октября 2021",
    reviewText:
      "Приехал в лень звонка, все тщательно обследовал на наличие теплопотерь.\nВ каждом случае объяснил какой материал покупать и что нужно сделать с наименьшими затратами. На месте наглядно все начертил и объяснил, ответил на все интересующие вопросы. К тому же дополнительно обратил внимание на другие недочёты в доме и посоветовал что нужно сделать. При любых вопросах готов дальше сотрудничать по телефону. Однозначно рекомендую его как выскоклассного специалиста в области строительства, и в частности тепловизионного обследования.",
  },
  {
    rating: 5,
    name: "Elena",
    serviceName: "Контроль строительства",
    date: "10 сентября 2021",
    reviewText:
      "Геннадий осуществляет технический надзор строительства частного дома в Мелихово . Специалист грамотный , всегда на связи , за время пока идёт стройка корректирует ошибки строителей и следит за точностью исполнения проекта . При необходимости даёт рекомендации как лучше сделать и почему .\nРекомендую специалиста",
  },
  {
    rating: 5,
    name: "Ксения",
    serviceName: "Экспертиза ремонта",
    date: "9 июня 2021",
    reviewText:
      "Здесь я могу выразить своё мнение и как заказчик и как менеджер по персоналу. Мой большой опыт позволяет мне говорить о том, что Геннадий Меграбян высокопрофессиональный специалист и знает все тонкости своей работы. Обратились за помощью в решении проблем с ремонтом квартиры. Нужна была экспертиза проделанных работ и план дальнейших действий. Геннадий сразу приехал, провел доскональную качественную экспертизу и составил план действий. Сказать спасибо, это ничего не сказать, так как после прояснения Геннадием ситуации мы сэкономили время, деньги и избавились от возможных проблем в будущем. Профессианализм специалиста был также оценён нашим застройщиком (крупная иностранная компания) и нашими строителями (фирма с большим опытом работы) и те и другие, после аргументированного заключения Геннадия, были согласны всё переделать за свой счет. С полной ответственностью рекомендую данного специалиста и восхищаюсь его работой.",
  },
  {
    rating: 5,
    name: "Александр",
    serviceName: "Тепловизионные обследования",
    date: "3 февраля 2021",
    reviewText:
      "Приехал в оговоренный день / оговоренное время.\nВыявил недостатки в отоплении (включая те, о которых не подозревал).\nПредоставил отчет, фото и рекомендации.\nОперативно, не дорого.\nЗатрудняюсь с тем что еще мне пожелать как заказчику.",
  },
  {
    rating: 5,
    name: "Олег",
    serviceName: "Тепловизионные обследования",
    date: "21 сентября 2020",
    reviewText:
      "Мастер приехал в согласованное время, при себе имел необходимое оборудование. Обследование (выявление протечки) выполнено, предположительное место ремонта оказалось вне зоны визуального просмотра (ниже уровня пола, в стяжке)",
  },
  {
    rating: 5,
    name: "Екатерина",
    serviceName: "Технический надзор",
    date: "13 августа 2020",
    reviewText:
      "Работа выполнена чётко, замечания по стройке давались конкретно, с проведением всех необходимых замеров! вместе с замечаниями Геннадий также предоставлял фото с размерами отклонений от проекта, что позволяло быстро исправлять недочеты!\n\nрекомендую в качестве специалиста по тех.надзору !",
  },
  {
    rating: 5,
    name: "Ирина",
    serviceName: "Технический надзор",
    date: "7 августа 2020",
    reviewText:
      "Геннадий работает уверенно, профессионально и пунктуально. Произвёл замеры, дал рекомендации по исправлению несоответствий для прораба, доступно ответил на вопросы, через несколько часов направил отчёт с выводами. Работой на 100% довольна. Осуществлена проверка качества земляных работ под фундамент и перепада уровня земли.",
  },
  {
    rating: 5,
    name: "Игорь Леонидович",
    serviceName: "Технический надзор",
    date: "23 июня 2020",
    reviewText:
      "Просил Геннадия проверить качество гидроизоляции цокольного этажа, конструктивных узлов, а также черновой отделки 3 этажного коттеджа,\nТехнадзор был тщательным, заключение профессиональным и понятным для реализации.\nДоволен работой Геннадия.",
  },
  {
    rating: 5,
    name: "Иван",
    serviceName: "Проектирование электроснабжения",
    date: "14 мая 2020",
    reviewText: "",
  },
  {
    rating: 5,
    name: "Нина",
    serviceName: "Экспертиза ремонта",
    date: "21 марта 2020",
    reviewText:
      "Работа проведена профессионально в точности с моими пожеланиями.",
  },
  {
    rating: 5,
    name: "Павел",
    serviceName: "Экспертиза ремонта",
    date: "9 декабря 2019",
    reviewText: "Сделал все хорошо, грамотный специалист",
  },
  {
    rating: 5,
    name: "Сергей",
    serviceName: "Строительная экспертиза",
    date: "15 ноября 2019",
    reviewText:
      'Компетентный, знающий свое дело на "5" специалист. На все вопросы дает полные и раскрытые ответы со ссылкой на нормативную базу. Рекомендую.',
  },
  {
    rating: 5,
    name: "Дарина",
    serviceName: "Строительная экспертиза",
    date: "18 октября 2019",
    reviewText:
      "Все замечательно,профессионал своего дела,даже сделано было все в более развёрнутом формате,благодарю за достойную работу,теперь не переживаю,что Вы *бдите*за ходом моего строительства,на связи...",
  },
  // {
  //   rating: 5,
  //   name: "Галина",
  //   serviceName: "Обмерочный чертёж",
  //   date: "13 октября 2019",
  //   reviewText: "Быстро, качественно",
  // },
  {
    rating: 5,
    name: "Алексей",
    serviceName: "Технический надзор",
    date: "6 октября 2019",
    reviewText: "Профессионал своего дела.",
  },
  // {
  //   rating: 5,
  //   name: "Полина",
  //   serviceName: "Обмерочный чертёж",
  //   date: "16 сентября 2019",
  //   reviewText:
  //     "Геннадий отлично выполнил задание!\nПрибыл на место назначения даже чуть раньше. Замерил помещение за 35 минут. Схемы подробные.\nОчень довольна!",
  // },
  // {
  //   rating: 5,
  //   name: "Ильнур",
  //   serviceName: "Обмерочный чертёж",
  //   date: "16 сентября 2019",
  //   reviewText:
  //     "Спасибо Геннадию очень оперативно приступил к работе, спокойно отнёсся к допникам, не входящим в ТЗ. Рекомендую",
  // },
  // {
  //   rating: 5,
  //   name: "Катерина",
  //   serviceName: "Чертёжные работы",
  //   date: "11 сентября 2019",
  //   reviewText:
  //     "Все сделано в срок,пунктуально,все вопросы по делу.Огромное спасибо,буду еще обращаться",
  // },
  // {
  //   rating: 5,
  //   name: "Сергей",
  //   serviceName: "Конструкторы",
  //   date: "6 сентября 2019",
  //   reviewText:
  //     "Все отлично, прекрасный специалист, выполнил работу качественно и своевременно. Рекомендую.",
  // },
  // {
  //   rating: 5,
  //   name: "Ольга",
  //   serviceName: "Обмерочный чертёж",
  //   date: "5 сентября 2019",
  //   reviewText:
  //     "Сделал два варианта расчетов при изменении запроса,пунктуален,задачи выполнены в срок",
  // },
  {
    rating: 5,
    name: "Евгений",
    serviceName: "Чертёжные работы",
    date: "30 августа 2019",
    reviewText:
      "Спасибо за проделанную работу.\nУчёл все правки и множество пожеланий.",
  },
  {
    rating: 5,
    name: "Ирина",
    serviceName: "Чертёжные работы",
    date: "29 августа 2019",
    reviewText:
      "Быстро, качественно без лишней демагогии сделаны чертежи. Рекомендации.",
  },
];

export default reviewData;
