import {AiOutlineHome} from "react-icons/ai";
import styles from "../components/NavMenu/NavMenu.module.scss";

const navLinks = [
    {
        // icon: <AiOutlineHome className={styles.navIcon} />,
        title: "Главная",
        routeName: "/",
    },
    {
        // icon: <AiOutlineSolution className={styles.navIcon} />,
        title: "Услуги",
        routeName: "services",
    },
    {
        // icon: <AiOutlineShopping className={styles.navIcon} />,
        title: "Цены",
        routeName: "prices",
    },
    {
        // icon: <AiOutlineTool className={styles.navIcon} />,
        title: "Оборудование",
        routeName: "equipment",
    },
    {
        // icon: <AiOutlineSchedule className={styles.navIcon} />,
        title: "Контакты",
        routeName: "contacts",
    },
    {
        // icon: <AiOutlineTrophy className={styles.navIcon} />,
        title: "Отзывы",
        routeName: "reviews",
    },
];

export default navLinks;