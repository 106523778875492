import styles from "./Equipment.module.scss";
import equipmentData from "./equipment.data";

export default function Equipment() {
  return (
    <div className={styles.root}>
      <div className={styles.contentWrap}>
        <h1 className={styles.h1}>Оборудование</h1>
        <div className={styles.text}>
          Основное оборудование для осуществления строительного контроля
          имеющееся в собственности.
        </div>
        <div className={styles.equipmentWrap}>
          {equipmentData.map((item) => {
            return (
              <div className={styles.equipmentItem} key={item.name}>
                <img
                  className={styles.equipmentItemImg}
                  src={item.img}
                  alt={item.name}
                />
                <div className={styles.equipmentItemInfo}>
                  <p
                    className={`${styles.equipmentItemInfoText} ${styles.title}`}
                  >
                    {item.name}
                  </p>
                  {!!item.number && (
                    <p className={styles.equipmentItemInfoText}>
                      Заводской номер: {item.number}
                    </p>
                  )}

                  {!!item.regNumber && (
                    <p className={styles.equipmentItemInfoText}>
                      Регистрационный номер СИ:{" "}
                      {item.regNumberLink ? (
                        <a
                          className={styles.equipmentItemInfoTextLink}
                          href={item.regNumberLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.regNumber}
                        </a>
                      ) : (
                        item.regNumber
                      )}
                    </p>
                  )}

                  {!!item.sertCode && (
                    <p className={styles.equipmentItemInfoText}>
                      Свидетельство:{" "}
                      {item.sertLink ? (
                        <a
                          className={styles.equipmentItemInfoTextLink}
                          href={item.sertLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.sertCode}
                        </a>
                      ) : (
                        item.sertCode
                      )}
                    </p>
                  )}

                  {/*{!!item.dateUntil && (*/}
                  {/*  <p className={styles.equipmentItemInfoText}>*/}
                  {/*    Действительно до: {item.dateUntil}*/}
                  {/*  </p>*/}
                  {/*)}*/}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
