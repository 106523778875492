const styles = {
  bmBurgerButton: {
    position: "relative",
    width: "30px",
    height: "20px",
    // right: "20px",
    // top: "46px",
  },
  bmBurgerBars: {
    background: "#222831",
  },
  bmBurgerBarsHover: {
    background: "#009fe3",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#eeeeee",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: 0,
  },
  bmMenu: {
    background: "#009fe3",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#eeeeee",
    padding: "1em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

export default styles;
