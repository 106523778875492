import PrePurchaseInspection from "./PrePurchaseInspection";
import ThermalInspection from "./ThermalInspection";
import ConstructionExpertise from "./ConstructionExpertise";
import EngeneeringComsExpertise from "./EngeneeringComsExpertise";
import RepairExpertise from "./RepairExpertise";
import ForensicExamination from "./ForensicExamination";

export default {
    PrePurchaseInspection,
    ThermalInspection,
    ConstructionExpertise,
    EngeneeringComsExpertise,
    RepairExpertise,
    ForensicExamination
};
