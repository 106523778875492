import styles from "./Services.module.scss";
import ServicesList from "@components/ServicesList";

export default function Services() {
  return (
    <div className={styles.root}>
      <h1 className={styles.h1}>Наши Услуги</h1>
      <ServicesList />
    </div>
  );
}
