const PUB_URL = process.env.PUBLIC_URL;

const equipmentData = [
  {
    name: "Дальномер лазерный RGK-80",
    img: `${PUB_URL}/photos/equipment/dalnomer.jpg`,
    number: "18I153404",
    regNumber: "67788-17",
    regNumberLink: "https://fgis.gost.ru/fundmetrology/registry/4/items/381762",
    sertCode: "С-ВЮМ/16-09-2022/188099508",
    sertLink: "https://fgis.gost.ru/fundmetrology/cm/results/188099508",
    dateUntil: "16.09.2023",
  },
  {
    name: "Анемометр TESTO 410-1",
    img: `${PUB_URL}/photos/equipment/Testo_410-1.jpg`,
    number: "38478972/912",
    regNumber: "",
    regNumberLink: "",
    sertCode: "А-2224-22",
    sertLink: "",
    dateUntil: "05.05.2023",
  },
  {
    name: "Измеритель влажности TESTO 606-2",
    img: `${PUB_URL}/photos/equipment/testo_606_2.jpg`,
    number: "38776554/002",
    regNumber: "",
    regNumberLink: "",
    sertCode: "18898",
    sertLink: "",
    dateUntil: "05.05.2023",
  },
  {
    name: "Тепловизор инфракрасный Testo 865",
    img: `${PUB_URL}/photos/equipment/testo_865.jpg`,
    number: "62340045",
    regNumber: "",
    regNumberLink: "",
    sertCode: "01177-22",
    sertLink: "",
    dateUntil: "27.05.2023",
  },
  {
    name: "Тестер измерительный электронный TESTO 750-2",
    img: `${PUB_URL}/photos/equipment/Testo_750-2.jpg`,
    number: "0000189",
    regNumber: "",
    regNumberLink: "",
    sertCode: "01163-22",
    sertLink: "",
    dateUntil: "05.05.2023",
  },
  {
    name: "Склерометр RGK-SK-60",
    img: `${PUB_URL}/photos/equipment/rgk-sk60.jpg`,
    number: "5001",
    regNumber: "",
    regNumberLink: "",
    sertCode: "1984/R",
    sertLink: "",
    dateUntil: "08.08.2023",
  },
  {
    name: "Нивелир лазерный RGK UL-11",
    img: `${PUB_URL}/photos/equipment/Lazernyy_postroitel_ploskostey.jpg`,
    number: "F1/538461",
    regNumber: "",
    regNumberLink: "",
    sertCode: "2482/R",
    sertLink: "",
    dateUntil: "10.05.2022",
  },
  {
    name: "Клин для контроля зазоров К-15",
    img: `${PUB_URL}/photos/equipment/klin_k15.jpg`,
    number: "351",
    regNumber: "",
    regNumberLink: "",
    sertCode: "351",
    sertLink: "",
    dateUntil: "14.04.2023",
  },
  {
    name: "Угломер ADA Anglemeter 45",
    img: `${PUB_URL}/photos/equipment/ada_anglemeter.jpg`,
    number: "",
    regNumber: "",
    regNumberLink: "",
    sertCode: "2411/R",
    sertLink: "",
    dateUntil: "16.09.2023",
  },
  {
    name: "Рейка уровень 2м",
    img: `${PUB_URL}/photos/equipment/stabila_2m.jpg`,
    number: "BN/12675",
    regNumber: "",
    regNumberLink: "",
    sertCode: "5150/V",
    sertLink: "",
    dateUntil: "29.09.2023",
  },
  {
    name: "Толщиномер DPM-816",
    img: `${PUB_URL}/photos/equipment/DPM-816.jpg`,
    number: "3025",
    regNumber: "",
    regNumberLink: "",
    sertCode: "Паспорт - первичная",
    sertLink: "",
    dateUntil: "08.08.2023",
  },
  {
    name: "Рулетка строительная  Р5УЗП",
    img: `${PUB_URL}/photos/equipment/ruletka-5m.jpg`,
    number: "И14249",
    regNumber: "",
    regNumberLink: "",
    sertCode: "Паспорт - первичная",
    sertLink: "",
    dateUntil: "12.04.2023",
  },
  {
    name: "Нивелир ADA Basis",
    img: `${PUB_URL}/photos/equipment/ada_basis.jpg`,
    number: "700612",
    regNumber: "43704-10",
    regNumberLink: "https://fgis.gost.ru/fundmetrology/registry/4/items/354440",
    sertCode: "С-ГКФ/29-04-2022/152528250",
    sertLink: "https://fgis.gost.ru/fundmetrology/cm/results/1-152528250",
    dateUntil: "18.04.2023",
  },
  {
    name: "Рейка геодезическая 5м",
    img: `${PUB_URL}/photos/equipment/reika_5m.webp`,
    number: "",
    regNumber: "",
    regNumberLink: "",
    sertCode: "",
    sertLink: "",
    dateUntil: "",
  },
  {
    name: "линейка 150мм",
    img: `${PUB_URL}/photos/equipment/lineika_150.jpg`,
    number: "722858",
    regNumber: "74468-19",
    regNumberLink: "",
    sertCode: "С-ВЮМ/22-09-2022/188393708",
    sertLink: "https://fgis.gost.ru/fundmetrology/cm/results/1-188393708",
    dateUntil: "21.09.2023",
  },
  {
    name: "Штанген циркуль ЩЦ 150-0,05",
    img: `${PUB_URL}/photos/equipment/shtangen.jpg`,
    number: "102115206",
    regNumber: "",
    regNumberLink: "",
    sertCode: "С-ВЮМ/22-09-2022/188393745",
    sertLink: "https://fgis.gost.ru/fundmetrology/cm/results/1-188393745",
    dateUntil: "21.09.2023",
  },
  {
    name: "Плотномер СГП-1М",
    img: `${PUB_URL}/photos/equipment/sgp-1m.jpg`,
    number: "07569",
    regNumber: "",
    regNumberLink: "",
    sertCode: "№В-25047-22",
    sertLink: "",
    dateUntil: "24.06.2023",
  },
  {
    name: "Измеритель защитного слоя бетона ИПА-МГ4",
    img: `${PUB_URL}/photos/equipment/ipa_mg4.jpg`,
    number: "3737",
    regNumber: "",
    regNumberLink: "",
    sertCode: "Паспорт - Первичная",
    sertLink: "",
    dateUntil: "05.04.2023",
  },
  {
    name: "Ультразвуковой тестер бетона UK1401m",
    img: `${PUB_URL}/photos/equipment/uk1401m.jpg`,
    number: "4011455",
    regNumber: "",
    regNumberLink: "",
    sertCode: "388682",
    sertLink: "",
    dateUntil: "09.03.2024",
  },
  {
    name: "Отрыв со скалыванием ПОС 60МГ4.О3",
    img: `${PUB_URL}/photos/equipment/pos_60mg4_03.jpg`,
    number: "38557",
    regNumber: "",
    regNumberLink: "",
    sertCode: "Паспорт - первичная",
    sertLink: "",
    dateUntil: "09.03.2024",
  },
  {
    name: "Рулетки геодезические 30м/50м",
    img: `${PUB_URL}/photos/equipment/ruletka_geodez.jpg`,
    number: "",
    regNumber: "",
    regNumberLink: "",
    sertCode: "",
    sertLink: "",
    dateUntil: "",
  },
  {
    name: "набор щупов-0,1-1,0мм",
    img: `${PUB_URL}/photos/equipment/shup.jpg`,
    number: "",
    regNumber: "",
    regNumberLink: "",
    sertCode: "",
    sertLink: "",
    dateUntil: "",
  },
  {
    name: "Детектор влаги Tramex RWS",
    img: `${PUB_URL}/photos/equipment/tramex-rws.jpg`,
    number: "",
    regNumber: "",
    regNumberLink: "",
    sertCode: "",
    sertLink: "",
    dateUntil: "",
  },
  // {
  //   name: "",
  //   img: ``,
  //   number: "",
  //   regNumber: "",
  //   regNumberLink: "",
  //   sertCode: "",
  //   sertLink: "",
  //   dateUntil: "",
  // },
];

export default equipmentData;
