import styles from "./ServiceContent.module.scss";

export default function ConstructionExpertise() {
  return (
    <div className={styles.root}>
      <p className={styles.paragraph}>
        Строительный контроль (технический надзор)
      </p>
      <p className={styles.paragraph}>Мы помогаем строить дома - правильно!</p>
      <p className={styles.paragraph}>
        Проверим все выполняемые работы, дадим рекомендации как исправить и
        проконтролируем исправление.
      </p>
      <p className={styles.listHeader}>
        Выполняем проверку следующих видов работ:
      </p>
      <ul className={styles.list}>
        <li>1. Уплотнение песка под фундамент</li>
        <li>2. Геометрии, линейных размеров, диагоналей и горизонтальности.</li>
        <li>3. Армирования строительных конструкций</li>
        <li>4. Прочность бетонной смеси.</li>
        <li>5. Разбивка первого ряда блоков и расстановка проёмов.</li>
        <li>
          6. Проверка кладки стен, линейные размеры, вертикальность, положение и
          высоту проемов.
        </li>
        <li>
          7. Устройство монолитного перекрытия и монтажа сборных ЖБ конструкций.
        </li>
        <li>8. Правильность сборки стропильной системы.</li>
        <li>
          9. Правильность утепления кровли и правильности монтажа мембран.
        </li>
        <li>
          И т.д. в общем осуществляем полный комплекс проверок при строительстве
          объектов любой сложности.
        </li>
      </ul>

      <p className={styles.paragraph}>
        Если у вас возникли сомнения в правильности выполнения работ вашими
        строителями, вызывайте нас и не стесняйтесь. Затраты на наши услуги
        окупаются в гораздо большем размере.
      </p>
      <p className={styles.paragraph}>
        В 20% случаев строители полностью переделывают свою работу
      </p>
      <p className={styles.paragraph}>
        В 5% случаев практически отсутствуют замечания,
      </p>
      <p className={styles.paragraph}>
        Оставшиеся 75% имеют замечания от серьезных до мелких и подлежат
        исправлению.
      </p>
    </div>
  );
}
