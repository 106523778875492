import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import Services from "./pages/Services";
import ServiceItem from "./pages/Services/ServiceItem";
import Prices from "./pages/Prices";
import Equipment from "./pages/Equipment";
import Contacts from "./pages/Contacts";
import Reviews from "./pages/Reviews";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Payment from "./pages/Payment";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [],
      },
      {
        path: "services",
        element: <Services />,
        children: [],
      },
      {
        path: "services/:service",
        element: <ServiceItem />,
        children: [],
      },
      {
        path: "prices",
        element: <Prices />,
        children: [],
      },
      {
        path: "equipment",
        element: <Equipment />,
        children: [],
      },
      {
        path: "contacts",
        element: <Contacts />,
        children: [],
      },
      {
        path: "reviews",
        element: <Reviews />,
        children: [],
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
        children: [],
      },
      {
        path: "payment",
        element: <Payment />,
        children: [],
      },
    ],
  },
]);
export default router;
