import styles from "./ServiceItem.module.scss";
import { services } from "@/data";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCoverflow } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import FeedbackForm from "@components/FeedbackForm";

export default function ServiceItem() {
  const { pathname } = useLocation();

  const serviceUrl = pathname
    .split("/")
    .filter((i) => i.length)
    .reverse()[0];
  const serviceData = services.find((item) => item.url === serviceUrl) || {};
  const swiperData = [];

  for (let i = 0; i < serviceData.photoLength; i++) {
    swiperData.push(`${serviceData.photoPath}/${i}.jpg`);
  }

  return (
    <div className={styles.root}>
      <div
        className={styles.hero}
        style={{ backgroundImage: `url(${serviceData.titleImg})` }}
      >
        <h1 className={styles.heroTitle}>{serviceData.title}</h1>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <serviceData.content />
        </div>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          spaceBetween={20}
          className={styles.slider}
          loop
          modules={[Pagination, Navigation, EffectCoverflow]}
          pagination={{
            clickable: true,
          }}
          navigation
        >
          {swiperData.map((i) => (
            <SwiperSlide
              key={i}
              className={styles.sliderSlide}
              style={{ backgroundImage: `url(${i})` }}
            />
          ))}
        </Swiper>
        <div className={styles.formWrap}>
          <FeedbackForm />
        </div>
      </div>
    </div>
  );
}
