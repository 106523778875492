import styles from "./Home.module.scss";
import ServicesList from "../../components/ServicesList";
import { sliderList } from "@/data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";

const slides = sliderList.map((i) => (
  <SwiperSlide
    key={i}
    className={styles.sliderSlide}
    style={{ backgroundImage: `url(${i})` }}
  />
));

export default function Home() {
  return (
    <div className={styles.root}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        className={styles.slider}
        loop
        modules={[Pagination, Navigation]}
        pagination={{
          clickable: true,
        }}
        navigation
      >
        {slides}
      </Swiper>
      <div className={styles.contentWrap}>
        <h1 className={styles.h1}>Контроль строительства и ремонта</h1>
        <div className={styles.text}>
          Контроль качества в строительстве (технадзор) основная функция
          проверка качества работы строителей, с обеспечением наилучшего
          результата. Окупаемость затрат в течении одного часа.
        </div>
        <h2 className={styles.h2}>Наши услуги</h2>
        <ServicesList />
      </div>
    </div>
  );
}
