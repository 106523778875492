import ServiceContent from "@/components/ServiceContent";

const PUB_URL = process.env.PUBLIC_URL;

const services = [
  {
    id: 1,
    url: "pre-purchase-inspection",
    title: "Экспертиза дома перед покупкой",
    titleImg: `${PUB_URL}/photos/pre-purchase-inspection/0.jpg`,
    content: ServiceContent.PrePurchaseInspection,
    photoPath: `${PUB_URL}/photos/pre-purchase-inspection`,
    photoLength: 10,
  },
  {
    id: 3,
    url: "construction-expertise",
    title: "Экспертиза строительства",
    titleImg: `${PUB_URL}/photos/construction-expertise/0.jpg`,
    content: ServiceContent.ConstructionExpertise,
    photoPath: `${PUB_URL}/photos/construction-expertise`,
    photoLength: 16,
  },
  {
    id: 4,
    url: "repair-expertise",
    title: "Экспертиза ремонта",
    titleImg: `${PUB_URL}/photos/repair-expertise/0.jpg`,
    content: ServiceContent.RepairExpertise,
    photoPath: `${PUB_URL}/photos/repair-expertise`,
    photoLength: 14,
  },
  {
    id: 5,
    url: "engineering-communications-expertise",
    title: "Экспертиза инженерных коммуникаций",
    titleImg: `${PUB_URL}/photos/engineering-communications-expertise/0.jpg`,
    content: ServiceContent.EngeneeringComsExpertise,
    photoPath: `${PUB_URL}/photos/engineering-communications-expertise`,
    photoLength: 17,
  },
  {
    id: 6,
    url: "thermal-inspection",
    title: "Тепловизионное обследование",
    titleImg: `${PUB_URL}/photos/thermal-inspection/0.jpg`,
    content: ServiceContent.ThermalInspection,
    photoPath: `${PUB_URL}/photos/thermal-inspection`,
    photoLength: 16,
  },
  // {
  //   id: 2,
  //   url: "forensic-examination",
  //   title: "судебная экспертиза",
  //   titleImg: `${PUB_URL}/photos/forensic-examination/0.jpg`,
  //   content: ServiceContent.ForensicExamination,
  //   photoPath: `${PUB_URL}/photos/forensic-examination`,
  //   photoLength: 26,
  // },


];

export default services;
