import styles from "./NavMenu.module.scss";
import { NavLink, useLocation } from "react-router-dom";

import { navLinks } from "../../data";
import { useMediaQuery } from "react-responsive";
// import { AiOutlineHome } from "react-icons/ai";
// import { AiOutlineSolution } from "react-icons/ai";
// import { AiOutlineShopping } from "react-icons/ai";
// import { AiOutlineTool } from "react-icons/ai";
// import { AiOutlineSchedule } from "react-icons/ai";
// import { AiOutlineTrophy } from "react-icons/ai";

export default function NavMenu() {
  const is960 = useMediaQuery({ query: "(min-width: 960px)" });
  const is820 = useMediaQuery({ query: "(min-width: 820px)" });
  // const location = useLocation();
  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        {is960 && (
          <li className={styles.navItem}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
            >
              Главная
            </NavLink>
          </li>
        )}

        <li className={styles.navItem}>
          <NavLink
            to="services"
            className={({ isActive }) =>
              isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
            }
          >
            Услуги
          </NavLink>
        </li>

        <li className={styles.navItem}>
          <NavLink
            to="prices"
            className={({ isActive }) =>
              isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
            }
          >
            Цены
          </NavLink>
        </li>

        {is820 && (
          <li className={styles.navItem}>
            <NavLink
              to="equipment"
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
            >
              Оборудование
            </NavLink>
          </li>
        )}

        {is820 && (
          <li className={styles.navItem}>
            <NavLink
              to="contacts"
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
            >
              Контакты
            </NavLink>
          </li>
        )}

        {is820 && (
          <li className={styles.navItem}>
            <NavLink
              to="reviews"
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
            >
              Отзывы
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
}
