import styles from "./PrivacyPolicy.module.scss";

export default function PrivacyPolicy() {
  return (
    <div className={styles.root}>
      <h1 className={styles.h1}>
        Политика в отношении обработки персональных данных
      </h1>
      <div className={styles.content}>
        <p>
          <b>1. Общие положения</b>
        </p>

        <p>
          Настоящая политика обработки персональных данных составлена в
          соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ
          «О персональных данных» и определяет порядок обработки персональных
          данных и меры по обеспечению безопасности персональных данных,
          предпринимаемые Меграбян Геннадием Юрьевичем (далее – Оператор).
        </p>

        <p>
          1.1. Оператор ставит своей важнейшей целью и условием осуществления
          своей деятельности соблюдение прав и свобод человека и гражданина при
          обработке его персональных данных, в том числе защиты прав на
          неприкосновенность частной жизни, личную и семейную тайну.
        </p>

        <p>
          1.2. Настоящая политика Оператора в отношении обработки персональных
          данных (далее – Политика) применяется ко всей информации, которую
          оператор может получить о посетителях веб-сайта brick-home.ru.
          Оператор не контролирует и не несет ответственности за сайты третьих
          лиц, на которые Пользователь может перейти по ссылкам, доступным на
          Сайте brick-home.ru.
        </p>

        <p>
          <b>2. Основные понятия, используемые в Политике</b>
        </p>

        <p>
          2.1. Автоматизированная обработка персональных данных – обработка
          персональных данных с помощью средств вычислительной техники;
        </p>

        <p>
          2.2. Блокирование персональных данных – временное прекращение
          обработки персональных данных (за исключением случаев, если обработка
          необходима для уточнения персональных данных);
        </p>

        <p>
          2.3. Веб-сайт – совокупность графических и информационных материалов,
          а также программ для ЭВМ и баз данных, обеспечивающих их доступность в
          сети интернет по сетевому адресу brick-home.ru;
        </p>

        <p>
          2.4. Информационная система персональных данных — совокупность
          содержащихся в базах данных персональных данных, и обеспечивающих их
          обработку информационных технологий и технических средств;
        </p>

        <p>
          2.5. Обезличивание персональных данных — действия, в результате
          которых невозможно определить без использования дополнительной
          информации принадлежность персональных данных конкретному Пользователю
          или иному субъекту персональных данных;
        </p>

        <p>
          2.6. Обработка персональных данных – любое действие (операция) или
          совокупность действий (операций), совершаемых с использованием средств
          автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных; Оператор
          осуществляет обработку данных пользователя в течение установленного
          настоящей Политикой срока обработки соответствующей категории
          персональных данных либо до момента отзыва субъектом персональных
          данных своего согласия на обработку персональных данных.
        </p>

        <p>
          2.7. Оператор – Администрация сайта, Меграбян Геннадий Юрьевич (Адрес:
          м.о., г. Мытищи, ул. Борисовка, д.4, кв.295)
        </p>

        <p>
          2.8. Персональные данные – любая информация, относящаяся прямо или
          косвенно к определенному или определяемому Пользователю веб-сайта
          brick-home.ru;
        </p>

        <p>2.9. Пользователь – любой посетитель веб-сайта brick-home.ru;</p>

        <p>
          2.10. Предоставление персональных данных – действия, направленные на
          раскрытие персональных данных определенному лицу или определенному
          кругу лиц;
        </p>

        <p>
          2.11. Распространение персональных данных – любые действия,
          направленные на раскрытие персональных данных неопределенному кругу
          лиц (передача персональных данных) или на ознакомление с персональными
          данными неограниченного круга лиц, в том числе обнародование
          персональных данных в средствах массовой информации, размещение в
          информационно-телекоммуникационных сетях или предоставление доступа к
          персональным данным каким-либо иным способом;
        </p>

        <p>
          2.13. Уничтожение персональных данных – любые действия, в результате
          которых персональные данные уничтожаются безвозвратно с невозможностью
          дальнейшего восстановления содержания персональных данных в
          информационной системе персональных данных и (или) уничтожаются
          материальные носители персональных данных.
        </p>

        <p>
          <b>
            3. Оператор может обрабатывать следующие персональные данные
            Пользователя
          </b>
        </p>

        <p>
          3.1. Персональная информация, которую Пользователь предоставляет о
          себе самостоятельно при регистрации (создании учетной записи) или в
          процессе использования Сайта и его сервисов, включая персональные
          данные Пользователя. Обязательная для предоставления Сервисов
          информация помечена специальным образом. Иная информация
          предоставляется Пользователем на его усмотрение.
        </p>

        <p>
          3.2. Данные, которые автоматически передаются сервисам Сайта в
          процессе их использования с помощью установленного на устройстве
          Пользователя программного обеспечения, в том числе IP-адрес, данные
          файлов cookie, информация о браузере Пользователя (или иной программе,
          с помощью которой осуществляется доступ к сервисам), технические
          характеристики оборудования и программного обеспечения, используемых
          Пользователем, дата и время доступа к сервисам, адреса запрашиваемых
          страниц, реферер (адрес предыдущей страницы) и иная подобная
          информация.
        </p>

        <p>
          <b>4. Цели обработки персональных данных</b>
        </p>

        <p>
          4.1. Сайт собирает и хранит только ту персональную информацию, которая
          необходима для предоставления информации об услугах или исполнения
          соглашений и договоров с Пользователем, за исключением случаев, когда
          законодательством предусмотрено обязательное хранение персональной
          информации в течение определенного законом срока.
        </p>

        <p>
          4.2. Персональную информацию Пользователя Сайт обрабатывает в
          следующих целях:
        </p>

        <p>
          4.2.1. Установления с Пользователем обратной связи, включая
          направление уведомлений, запросов, касающихся использования Сайта,
          оказания услуг, обработку запросов и заявок от Пользователя. Для
          достижения данной цели Оператор собирает и обрабатывает следующие
          категории персональных данных: имя, адрес электронной почты,
          контактный номер телефона. К субъектам, персональные данные которых
          обрабатываются для указанной цели, относятся: физические лица,
          заинтересованные в получении товаров/работ/услуг от Оператора,
          физические лица, состоящие в гражданско-правовых и иных договорных
          отношениях с Оператором, представители юридических лиц - контрагентов
          Оператора либо потенциально заинтересованных в установлении с ним
          гражданско-правовых отношений. Указанные персональные данные
          обрабатываются смешанным способом. Срок обработки и хранения
          персональных данных, собираемых в соответствии с настоящим пунктом,
          составляет не более 7 лет с момента получения последней заявки либо
          иного обращения от Пользователя. При получении Оператором заявления
          субъекта персональных данных с требованием о прекращении обработки
          персональных данных Оператор прекращает обработку персональных данных
          досрочно, а именно в срок, не превышающий десяти рабочих дней с даты
          получения соответствующего требования. Указанный срок может быть
          продлен, но не более чем на пять рабочих дней в случае направления
          оператором в адрес субъекта персональных данных мотивированного
          уведомления с указанием причин продления срока предоставления
          запрашиваемой информации.
        </p>

        <p>
          4.2.2. Идентификации Пользователя, зарегистрированного на Сайте, для
          формирования и исполнения персонализированных предложений и
          соглашений. Для достижения данной цели Оператор собирает и
          обрабатывает следующие категории персональных данных: имя, адрес
          электронной почты, контактный номер телефона, логин, пользовательский
          ID, IP-адрес. К субъектам, персональные данные которых обрабатываются
          для указанной цели, относятся: физические лица, заинтересованные в
          получении товаров/работ/услуг от Оператора, физические лица, состоящие
          в гражданско-правовых и иных договорных отношениях с Оператором,
          представители юридических лиц - контрагентов Оператора либо
          потенциально заинтересованных в установлении с ним гражданско-правовых
          отношений. Указанные персональные данные обрабатываются смешанным
          способом. Срок обработки и хранения персональных данных, собираемых в
          соответствии с настоящим пунктом, составляет не более 7 лет с момента
          получения последней заявки либо иного обращения от Пользователя. При
          получении Оператором заявления субъекта персональных данных с
          требованием о прекращении обработки персональных данных Оператор
          прекращает обработку персональных данных досрочно, а именно в срок, не
          превышающий десяти рабочих дней с даты получения соответствующего
          требования. Указанный срок может быть продлен, но не более чем на пять
          рабочих дней в случае направления оператором в адрес субъекта
          персональных данных мотивированного уведомления с указанием причин
          продления срока предоставления запрашиваемой информации.
        </p>

        <p>
          4.2.3. Предоставления Пользователю доступа к персонализированным
          ресурсам Сайта. Для достижения данной цели Оператор собирает и
          обрабатывает следующие категории персональных данных: имя, адрес
          электронной почты, контактный номер телефона, логин, пользовательский
          ID, IP-адрес. К субъектам, персональные данные которых обрабатываются
          для указанной цели, относятся: физические лица, заинтересованные в
          получении товаров/работ/услуг от Оператора, физические лица, состоящие
          в гражданско-правовых и иных договорных отношениях с Оператором,
          представители юридических лиц - контрагентов Оператора либо
          потенциально заинтересованных в установлении с ним гражданско-правовых
          отношений. Указанные персональные данные обрабатываются смешанным
          способом. Срок обработки и хранения персональных данных, собираемых в
          соответствии с настоящим пунктом составляет не более 7 лет с момента
          получения последней заявки либо иного обращения от Пользователя. При
          получении Оператором заявления субъекта персональных данных с
          требованием о прекращении обработки персональных данных Оператор
          прекращает обработку персональных данных досрочно, а именно в срок, не
          превышающий десяти рабочих дней с даты получения соответствующего
          требования. Указанный срок может быть продлен, но не более чем на пять
          рабочих дней в случае направления оператором в адрес субъекта
          персональных данных мотивированного уведомления с указанием причин
          продления срока предоставления запрашиваемой информации.
        </p>

        <p>
          4.2.4. Определения места нахождения Пользователя для обеспечения
          безопасности, предотвращения мошенничества. Для достижения данной цели
          Оператор собирает и обрабатывает следующие категории персональных
          данных: IP-адрес пользователя. К субъектам, персональные данные
          которых обрабатываются для указанной цели, относятся: физические лица,
          заинтересованные в получении товаров/работ/услуг от Оператора,
          физические лица, состоящие в гражданско-правовых и иных договорных
          отношениях с Оператором, представители юридических лиц - контрагентов
          Оператора либо потенциально заинтересованных в установлении с ним
          гражданско-правовых отношений. Указанные персональные данные
          обрабатываются смешанным способом. Срок обработки и хранения
          персональных данных, собираемых в соответствии с настоящим пунктом
          составляет не более 3 лет с момента последнего посещения Пользователем
          Сайта. При получении Оператором заявления субъекта персональных данных
          с требованием о прекращении обработки персональных данных Оператор
          прекращает обработку персональных данных досрочно, а именно в срок, не
          превышающий десяти рабочих дней с даты получения соответствующего
          требования. Указанный срок может быть продлен, но не более чем на пять
          рабочих дней в случае направления оператором в адрес субъекта
          персональных данных мотивированного уведомления с указанием причин
          продления срока предоставления запрашиваемой информации.
        </p>

        <p>
          4.2.5. Предоставления Пользователю эффективной клиентской и
          технической поддержки. Для достижения данной цели Оператор собирает и
          обрабатывает следующие категории персональных данных: имя, адрес
          электронной почты, контактный номер телефона, логин, пользовательский
          ID, IP-адрес. К субъектам, персональные данные которых обрабатываются
          для указанной цели, относятся: физические лица, заинтересованные в
          получении товаров/работ/услуг от Оператора, физические лица, состоящие
          в гражданско-правовых и иных договорных отношениях с Оператором,
          представители юридических лиц - контрагентов Оператора либо
          потенциально заинтересованных в установлении с ним гражданско-правовых
          отношений. Указанные персональные данные обрабатываются смешанным
          способом. Срок обработки и хранения персональных данных, собираемых в
          соответствии с настоящим пунктом составляет не более 7 лет с момента
          получения последней заявки либо иного обращения от Пользователя. При
          получении Оператором заявления субъекта персональных данных с
          требованием о прекращении обработки персональных данных Оператор
          прекращает обработку персональных данных досрочно, а именно в срок, не
          превышающий десяти рабочих дней с даты получения соответствующего
          требования. Указанный срок может быть продлен, но не более чем на пять
          рабочих дней в случае направления оператором в адрес субъекта
          персональных данных мотивированного уведомления с указанием причин
          продления срока предоставления запрашиваемой информации.
        </p>

        <p>
          4.2.6. Направления Пользователю уведомления о новых продуктах и
          услугах, специальных предложениях и различных событиях. Пользователь
          всегда может отказаться от получения информационных сообщений,
          направив Оператору письмо на адрес электронной почты
          Brick-home@mail.ru с пометкой «Отказ от уведомлений о новых продуктах
          и услугах и специальных предложениях». Для достижения данной цели
          Оператор собирает и обрабатывает следующие категории персональных
          данных: имя, адрес электронной почты, контактный номер телефона,
          логин, пользовательский ID. К субъектам, персональные данные которых
          обрабатываются для указанной цели, относятся: физические лица,
          заинтересованные в получении товаров/работ/услуг от Оператора,
          физические лица, состоящие в гражданско-правовых и иных договорных
          отношениях с Оператором, представители юридических лиц - контрагентов
          Оператора либо потенциально заинтересованных в установлении с ним
          гражданско-правовых отношений. Указанные персональные данные
          обрабатываются смешанным способом. Срок обработки и хранения
          персональных данных, собираемых в соответствии с настоящим пунктом,
          составляет не более 7 лет с момента получения последней заявки либо
          иного обращения от Пользователя. При получении Оператором заявления
          субъекта персональных данных с требованием о прекращении обработки
          персональных данных Оператор прекращает обработку персональных данных
          досрочно, а именно в срок, не превышающий десяти рабочих дней с даты
          получения соответствующего требования. Указанный срок может быть
          продлен, но не более чем на пять рабочих дней в случае направления
          оператором в адрес субъекта персональных данных мотивированного
          уведомления с указанием причин продления срока предоставления
          запрашиваемой информации.
        </p>

        <p>
          4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов
          интернет-статистики, служат для сбора информации о действиях
          Пользователей на сайте, улучшения качества сайта и его содержания. Для
          достижения данной цели Оператор собирает и обрабатывает следующие
          категории обезличенных данных: IP-адрес, данные файлов cookie,
          информация о браузере Пользователя (или иной программе, с помощью
          которой осуществляется доступ к сервисам), технические характеристики
          оборудования и программного обеспечения, используемых Пользователем,
          дата и время доступа к сервисам, адреса запрашиваемых страниц, реферер
          (адрес предыдущей страницы). Указанные данные обрабатываются машинным
          способом. Срок обработки и хранения обезличенных данных, собираемых в
          соответствии с настоящим пунктом, составляет не более 3 лет с момента
          последнего посещения Пользователем Сайта.
        </p>

        <p>
          <b>5. Правовые основания обработки персональных данных</b>
        </p>

        <p>
          5.1. Оператор обрабатывает персональные данные Пользователя только в
          случае их заполнения и/или отправки Пользователем самостоятельно через
          специальные формы, расположенные на сайте brick-home.ru. Заполняя
          соответствующие формы и/или отправляя свои персональные данные
          Оператору, Пользователь выражает свое согласие с данной Политикой.
        </p>

        <p>
          5.2. Оператор обрабатывает обезличенные данные о Пользователе в
          случае, если это разрешено в настройках браузера Пользователя
          (включено сохранение файлов «cookie» и использование технологии
          JavaScript).
        </p>

        <p>
          <b>
            6. Порядок сбора, хранения, передачи и других видов обработки
            персональных данных
          </b>
        </p>

        <p>
          6.1. Персональная информация Пользователей хранится на территории
          Российской Федерации с соблюдением всех требований, установленных
          действующим российским законодательством.
        </p>

        <p>
          6.2. В отношении персональной информации Пользователя сохраняется ее
          конфиденциальность, кроме случаев добровольного предоставления
          Пользователем информации о себе для общего доступа неограниченному
          кругу лиц. Согласие на обработку персональных данных, разрешенных
          субъектом персональных данных для распространения, оформляется
          отдельно от иных согласий субъекта персональных данных на обработку
          его персональных данных в соответствии с требованиями ФЗ “О
          персональных данных”.
        </p>

        <p>
          6.3. Сайт вправе передать персональную информацию Пользователя третьим
          лицам в следующих случаях:
        </p>

        <p>6.3.1. Пользователь выразил согласие на такие действия.</p>

        <p>
          6.3.2. Передача необходима для использования Пользователем
          определенного сервиса либо для исполнения определенного соглашения или
          договора с Пользователем.
        </p>

        <p>
          6.3.4. Передача предусмотрена российским или иным применимым
          законодательством в рамках установленной законодательством процедуры.
        </p>

        <p>
          6.3.5. В случае продажи либо иного отчуждения Сайта к приобретателю
          переходят все обязательства по соблюдению условий настоящей Политики
          применительно к полученной им персональной информации.
        </p>

        <p>
          6.4. Обработка персональных данных Пользователя осуществляется в
          течение установленных настоящей Политикой сроков любым законным
          способом, в том числе в информационных системах персональных данных с
          использованием средств автоматизации или без использования таких
          средств. Обработка персональных данных Пользователей осуществляется в
          соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О
          персональных данных".
        </p>

        <p>
          6.5. При утрате или разглашении персональных данных Администрация
          Сайта информирует Пользователя об утрате или разглашении персональных
          данных.
        </p>

        <p>
          6.6. Администрация Сайта принимает необходимые организационные и
          технические меры для защиты персональной информации Пользователя от
          неправомерного или случайного доступа, уничтожения, изменения,
          блокирования, копирования, распространения, а также от иных
          неправомерных действий третьих лиц.
        </p>

        <p>
          6.7. Администрация Сайта совместно с Пользователем принимает все
          необходимые меры по предотвращению убытков или иных отрицательных
          последствий, вызванных утратой или разглашением персональных данных
          Пользователя.
        </p>

        <p>
          <b>7. Порядок уничтожения персональных данных</b>
        </p>

        <p>
          7.1. Уничтожение персональных данных производится в следующих случаях:
        </p>

        <ul>
          <li>
            выявление неправомерной обработки персональных данных, в том числе
            по обращению субъекта персональных данных или его представителя либо
            запросу уполномоченного органа по защите прав субъектов персональных
            данных, если обеспечить правомерность обработки персональных данных
            невозможно;
          </li>
          <li>
            требования субъекта персональных данных, если его персональные
            данные являются неполными, устаревшими, неточными, незаконно
            полученными или не являются необходимыми для заявленной цели
            обработки;
          </li>
          <li>
            отзыва субъектом персональных данных согласия на обработку его
            персональных данных;
          </li>
          <li>
            достижения цели обработки персональных данных или утраты
            необходимости в достижении этих целей;
          </li>
          <li>истечения установленных сроков хранения персональных данных;</li>
          <li>
            признания недостоверности персональных данных или получения их
            незаконным путем по требованию уполномоченного органа по защите прав
            субъектов персональных данных;
          </li>
          <li>в иных установленных законодательством случаях.</li>
        </ul>

        <p>
          7.2. Уничтожение персональных данных может быть осуществлено двумя
          способами в зависимости от типа носителя информации (бумажный или
          электронный):
        </p>

        <ul>
          <li>
            физическое уничтожение носителя (уничтожение через шредерование,
            сжигание);
          </li>
          <li>
            уничтожение информации с носителя (многократная перезапись в
            секторах магнитного диска).
          </li>
        </ul>

        <p>
          7.3. Уничтожение части персональных данных, если это допускается
          материальным носителем, производится способом, исключающим дальнейшую
          обработку этих персональных данных, с сохранением возможности
          обработки иных данных, зафиксированных на материальном носителе.
        </p>

        <p>
          <b>8. Ответственность</b>
        </p>

        <p>
          8.1. Администрация Сайта, не исполнившая свои обязательства, несет
          ответственность за убытки, понесенные Пользователем в связи с
          неправомерным использованием персональных данных, в соответствии с
          законодательством Российской Федерации.
        </p>

        <p>
          8.2. В случае утраты или разглашения конфиденциальной информации
          Администрация Сайта не несет ответственности, если данная
          конфиденциальная информация:
        </p>

        <p>8.2.1. Стала публичным достоянием до ее утраты или разглашения.</p>

        <p>
          8.2.2. Была получена от третьей стороны до момента ее получения
          Администрацией Сайта.
        </p>

        <p>8.2.3. Была разглашена с согласия Пользователя.</p>

        <p>
          <b>9. Заключительные положения:</b>
        </p>

        <p>
          9.1. Администрация Сайта вправе вносить изменения в настоящую Политику
          конфиденциальности без согласия Пользователя.
        </p>

        <p>
          9.2. Новая Политика конфиденциальности вступает в силу с момента ее
          размещения на Сайте, если иное не предусмотрено новой редакцией
          Политики конфиденциальности.
        </p>

        <p>
          9.3. Все предложения или вопросы по настоящей Политике
          конфиденциальности следует сообщать на электронный адрес
          Brick-home@mail.ru.
        </p>

        <p>
          9.4. Действующая Политика конфиденциальности размещена на странице по
          адресу:{" "}
          <a href="/privacy-policy">
            brick-home.ru/privacy-policy
          </a>
          .
        </p>
      </div>
    </div>
  );
}
