import styles from "./ServiceContent.module.scss";

export default function ThermalInspection() {
  return (
    <div className={styles.root}>
      <p className={styles.paragraph}>
        Тепловизионное обследование необходимо для определения мест тепловых
        потерь зданий и их элементов. Имея большой опыт тепловизионных
        обследований мы выполним качественно и подробно обследование, дадим
        рекомендации по устранению дефектов конструкций приводящих к тепловым
        потерям зданий и сооружений.
      </p>

      <p className={styles.paragraph}>
        Явные признаки тепловых потерь, при которых необходимо заказать данную
        услугу:
      </p>
      <p className={styles.paragraph}>Температура в помещении ниже 18<sup><small>o</small></sup>С</p>
      <p className={styles.paragraph}>Холодные стены</p>
      <p className={styles.paragraph}>Грибок на откосах окон</p>
      <p className={styles.paragraph}>
        Сосульки на водосточной системе или лёд на кровле.
      </p>
    </div>
  );
}
