import styles from "./Prices.module.scss";
import pricesData from "@/pages/Prices/prices.data";
import {Link} from "react-router-dom";

export default function Prices() {
  return (
    <div className={styles.root}>
      <div className={styles.contentWrap}>
        <h1 className={styles.h1}>Наши цены</h1>
        <div className={styles.prices}>
          {pricesData.map((item) => (
            <div key={item.title}>
              <h2 className={styles.pricesHeader}>{item.title}</h2>
              <div className={styles.tableWrap}>
                <table className={styles.table}>
                  <thead>
                    <tr className={styles.tHeadRow}>
                      <th className={styles.tHeadCell}>Наименование работ</th>
                      <th className={styles.tHeadCell}>Цена, руб.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.data.map(({ value, price }) => (
                      <tr className={styles.tBodyRow} key={value}>
                        <td className={styles.tBodyCell}>{value}</td>
                        <td className={styles.tBodyCellPrice}>{price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <div className={styles.text}>
            *Среднее время нахождения на объекте 2 часа <br/> далее по согласованию
            с заказчиком 3000 руб./час.
          </div>
        </div>
      </div>
      <Link to="/payment" className={styles.hidden}>оплатить</Link>
    </div>
  );
}
