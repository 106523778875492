import styles from "./ServiceContent.module.scss";

export default function ForensicExamination() {
  return (
    <div className={styles.root}>
      <p className={styles.paragraph}>
        Мы проводим Судебные экспертизы, качественно и в срок.
      </p>
    </div>
  );
}
