import { Link } from "react-router-dom";
import { services } from "../../data";
import styles from "./ServicesList.module.scss";

export default function ServicesList() {
  return (
    <div className={styles.root}>
      {services.map((service) => {
        return (
          <Link
            to={`/services/${service.url}`}
            className={styles.service}
            key={service.id}
          >
            <div className={styles.wrap}>
              <div
                className={styles.img}
                style={{ backgroundImage: `url(${service.titleImg})` }}
              />
              <div className={styles.title}>{service.title}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
