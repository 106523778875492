import styles from "./ServiceContent.module.scss";

export default function EngeneeringComsExpertise() {
  return (
    <div className={styles.root}>
      <p className={styles.listHeader}>
        Выполняем проверку инженерных коммуникаций зданий:
      </p>
      <ul className={styles.list}>
        <li>1. Электрика</li>
        <li>2. ХВС, ГВС</li>
        <li>3. Отопление</li>
      </ul>
      <p className={styles.paragraph}>
        Электрика: <br />
        Проверка прокладки кабельных линий и положения розеток согласно проекта.
        В случае отсутствия общим нормам. Проверка сборки распределительного
        электрощита (номиналы автоматов, УЗО, дифференциальных автоматов, момент
        затяжки клемм, проверка изоляции межфазной, ноль-фаза, ноль-земля,
        земля-фаза), проверка УЗО и диф. автоматов на ток утечки. Проверка
        каждой розетки на ток утечки
      </p>
      <p className={styles.paragraph}>
        ХВС, ГВС: <br />
        Проверка общей сборки системы на соответствие нормам, испытание
        повышенным гидравлическим испытанием (до 10 кг/см2), проверка на
        протечки.
      </p>
      <p className={styles.paragraph}></p>
      <p className={styles.paragraph}>
        Отопление: <br />
        Проверка общей сборки системы на соответствие нормам, испытание
        повышенным гидравлическим испытанием (до 10 кг/см2), проверка на
        протечки, проверка правильности сборки системы на несколько контуров.
      </p>
    </div>
  );
}
